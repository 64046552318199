<template>
  <div
    v-if="media"
    class="file-item"
  >
    <b-link
      :href="media.AttachedFile ? media.AttachedFile.DownloadUrl : media.DownloadUrl"
      class="font-weight-bold"
    >
      <feather-icon icon="PaperclipIcon" />{{ media.AttachedFile ? media.AttachedFile.Label : media.Label }}
    </b-link>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink
  },
  data() {
    return {
      media: {}
    }
  },
  created() {
    this.$http.get(`media?id=${this.mediaId}`).then(response => {
      this.media = response.data.data
    })
  },
  setup() {
    return {}
  },
  methods: {},
  props: {
    mediaId: Number
  }
}
</script>

<style lang="scss">
.file-item {
  margin-bottom: 3px;
}
</style>
