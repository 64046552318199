<template>
  <section class="invoice-preview-wrapper">
    <validation-observer ref="simpleRules">
      <b-row class="invoice-preview">

        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="12"
          md="12"
        >
          <b-card
            no-body
            class="invoice-preview-card section-container"
          >
            <!-- Header -->
            <b-card-header>
              <h4 class="card-title">{{i18nT(`Task`)}}</h4>
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                    v-b-tooltip.hover.top="i18nT(`Actions`)"
                  />
                </template>
                <b-dropdown-item v-if="userType !== 'Freelancer' && iCan('tasks', 'write')" :to="{ name: 'edit-tasks', params: { id: task.Id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userType !== 'Freelancer' && iCan('tasks', 'write')" @click="onCopy()">
                  <feather-icon icon="CopyIcon" />
                  <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="completeTask()"
                  v-if="task.Completed != 1 && task.Template != 1"

                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">{{i18nT(`Complete`)}}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="completeTask()"
                  v-if="task.Completed == 1 && task.Template != 1 && userType !== 'Freelancer'"
                >
                  <feather-icon icon="RefreshCcwIcon" />
                  <span class="align-middle ml-50">{{i18nT(`Reopen`)}}</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userType !== 'Freelancer' && iCan('tasks', 'write')" @click="onDelete()">
                  <feather-icon
                    icon="TrashIcon"
                    color="red"
                  />
                  <span
                    class="align-middle ml-50"
                    style="color: red"
                  >{{i18nT(`Delete`)}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-header>
            <!-- Body -->
            <b-card-body class="pb-0">
              <div class="row">
                <div class="col-md-9 col-sm-12">
                  <h2 class="mb-2">
                    <a
                      class="task btn btn-icon rounded-circle mr-1"
                      v-b-tooltip.hover.right="'Complete task'"
                      @click.prevent="completeTask()"
                      :class="{'btn-gradient-secondary': completeBtnClicked, 'btn-gradient-primary': !completeBtnClicked }"
                      v-if="task.Completed === 0 && task.Template != 1"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        color="white"
                      />
                    </a>
                    <a
                      class="task btn btn-icon rounded-circle task mr-1"
                      v-b-tooltip.hover.right="'Reopen task'"
                      :class="{'btn-outline-secondary': completeBtnClicked, 'btn-outline-primary': !completeBtnClicked }"
                      @click.prevent="completeTask()"
                      v-if="task.Completed === 1 && task.Template != 1 && userType !== 'Freelancer'"
                    >
                      <feather-icon
                        icon="RefreshCcwIcon"
                        color="primary"
                      />
                    </a>
                    {{task.Label}}
                  </h2>

                  <div v-html="task.Description" />
                  <div v-if="task.AssignedUser">
                    <h6 class="section-label">{{i18nT(`Assigned to`)}}</h6>
                    <div class="detail-field pb-1">
                      <b-avatar
                        variant="light-primary"
                        class="mr-1 mb-1"
                        v-b-tooltip.hover.top="task.AssignedUser.Label"
                        :text="task.AssignedUser.FirstName.charAt(0).toUpperCase() + task.AssignedUser.LastName.charAt(0).toUpperCase()"
                        :to="employeeProfilePath"
                        target="_blank"
                        :src="task.AssignedUser.ImageUrl"
                      ></b-avatar>
                    </div>
                  </div>
                  <h6 class="section-label">{{i18nT(`Attachments`)}}</h6>
                  <div class="detail-field pb-1">
                    <div
                      v-for="file in medias"
                      :key="file"
                    >
                      <media-item :media-id="file" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-12 pr-2 pl-2">
                  <div class="bg-light text-dark p-2">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <h6 class="section-label">{{i18nT(`Created`)}}</h6>
                        <div class="detail-field pb-1">
                          {{task.CreatedAt | date}}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <h6 class="section-label">{{i18nT(`Updated`)}}</h6>
                        <div class="detail-field pb-1">
                          {{task.UpdatedAt | date}}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <h6 class="section-label">{{i18nT(`Due date`)}}</h6>
                        <div class="detail-field pb-1">
                          {{task.TaskDueDate | date}}
                          {{task.TaskRepeated}}
                        </div>
                      </div>
                      <div
                        class="col-md-6 col-12"
                        v-if="task.Template != 1"
                      >
                        <h6 class="section-label">{{i18nT(`Status`)}}</h6>
                        <div class="detail-field pb-1">
                          <a
                            class="badge badge-pill badge-light-danger"
                            v-if="task.TaskIsLate"
                          >
                            Late
                          </a>
                          <a
                            :class="task.Completed == 0 ? 'badge badge-pill badge-light-success': 'badge badge-pill badge-light-secondary'"
                            v-if="!task.TaskIsLate"
                          >
                            {{task.Completed == 0 ? 'Active': 'Completed'}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">
            <div v-if="task.Template != 1">
              <h4 class="ml-2">{{i18nT(`Comments`)}}</h4>
              <div class="pl-2 pr-2">
                <div
                  v-for="item in task.TaskComments"
                  :key="item.Id"
                  class="pt-1 border p-2 mb-2"
                >
                  <h5>
                    <feather-icon
                      icon="MessageCircleIcon"
                      size="16"
                    />
                    {{item.User.Label}}
                  </h5>
                  <span class="timestamp">{{i18nT(`Commented on`)}} {{item.CreatedAt}}</span>
                  <p
                    class="mt-2"
                    v-html="item.Message"
                  />

                  <div
                    v-for="file in item.Medias"
                    :key="file"
                  >
                    <media-item :media-id="file" />
                  </div>
                </div>
              </div>
              <hr class="invoice-spacing">
              <h4 class="ml-2" id="quill-editor-wrapper">{{i18nT(`Your comment`)}}</h4>
              <p class="ml-2">{{i18nT(`Ask a question or post an update.`)}}</p>
              <b-card-body class="pt-0">
                <validation-provider
                  #default="{ errors }"
                  name="Comment"
                  rules="required"
                >
                  <b-form-group
                    label=""
                    label-for="Comment"
                    class="mb-2"
                  >
                    <quill-editor
                      id="Comment"
                      v-model="comment"
                      :options="snowOption"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <div class="example-full">
                  <!-- <button type="button" class="btn btn-danger float-right btn-is-option" @click.prevent="isOption = !isOption">
                    <i class="fa fa-cog" aria-hidden="true"></i>
                    Options
                  </button> -->

                  <div
                    v-show="$refs.upload && $refs.upload.dropActive"
                    class="drop-active"
                  >
                    <h3>{{i18nT(`Drop files to upload.`)}}</h3>
                  </div>
                  <div
                    class="upload"
                    v-show="!isOption"
                  >
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{{i18nT(`Thumb`)}}</th>
                            <th>{{i18nT(`Name`)}}</th>
                            <th>{{i18nT(`Size`)}}</th>
                            <th>{{i18nT(`Speed`)}}</th>
                            <th>{{i18nT(`Status`)}}</th>
                            <th>{{i18nT(`Action`)}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="!files.length" class="sk-dropzone-boundary">
                            <td colspan="9">
                              <div class="text-center p-5">
                                <p>{{i18nT(`Drop files anywhere to upload.`)}}</p>
                                <label
                                  :for="name"
                                  class="btn btn-primary"
                                >
                                  <feather-icon icon="PlusCircleIcon" />
                                  {{i18nT(`Add file`)}}
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr
                            v-for="(file, index) in files"
                            :key="file.id"
                          >
                            <td>{{index + 1}}</td>
                            <td>
                              <img
                                class="td-image-thumb"
                                v-if="file.thumb"
                                :src="file.thumb"
                              />
                              <span v-else>{{i18nT(`No image`)}}</span>
                            </td>
                            <td>
                              <div class="filename">
                                {{file.name}}
                              </div>
                              <div
                                class="progress"
                                v-if="file.active || file.progress !== '0.00'"
                              >
                                <div
                                  :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}"
                                  role="progressbar"
                                  :style="{width: file.progress + '%'}"
                                >{{file.progress}}%</div>
                              </div>
                            </td>
                            <td>{{formatSize(file.size)}}</td>
                            <td>{{formatSize(file.speed)}}</td>

                            <td v-if="file.error">{{file.error}}</td>
                            <td v-else-if="file.success">{{i18nT(`Success`)}}</td>
                            <td v-else-if="file.active">{{i18nT(`Active`)}}</td>
                            <td v-else></td>
                            <td>
                              <div class="btn-group">
                                <button
                                  class="btn btn-secondary btn-sm dropdown-toggle"
                                  type="button"
                                >
                                  Action
                                </button>
                                <div class="dropdown-menu">
                                  <a
                                    :class="{'dropdown-item': true, disabled: !file.active}"
                                    href="#"
                                    @click.prevent="file.active ? $refs.upload.update(file, {error: 'cancel'}) : false"
                                  >{{i18nT(`Cancel`)}}</a>

                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    v-if="file.active"
                                    @click.prevent="$refs.upload.update(file, {active: false})"
                                  >{{i18nT(`Abort`)}}</a>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    v-else-if="file.error && file.error !== 'compressing' && file.error !== 'image parsing' && $refs.upload.features.html5"
                                    @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})"
                                  >{{i18nT(`Retry upload`)}}</a>
                                  <a
                                    :class="{'dropdown-item': true, disabled: file.success || file.error === 'compressing' || file.error === 'image parsing'}"
                                    href="#"
                                    v-else
                                    @click.prevent="file.success || file.error === 'compressing' || file.error === 'image parsing' ? false : $refs.upload.update(file, {active: true})"
                                  >{{i18nT(`Upload`)}}</a>

                                  <div class="dropdown-divider"></div>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    @click.prevent="$refs.upload.remove(file)"
                                  >{{i18nT(`Remove`)}}</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="example-foore invisible">
                      <!-- <div class="footer-status float-right">
                        Drop: {{$refs.upload ? $refs.upload.drop : false}},
                        Active: {{$refs.upload ? $refs.upload.active : false}},
                        Uploaded: {{$refs.upload ? $refs.upload.uploaded : true}},
                        Drop active: {{$refs.upload ? $refs.upload.dropActive : false}}
                      </div> -->
                      <div class="btn-group">
                        <file-upload
                          class="btn btn-primary dropdown-toggle"
                          :post-action="postAction"
                          :multiple="multiple"
                          :directory="directory"
                          :create-directory="createDirectory"
                          :size="size || 0"
                          :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                          :headers="headers"
                          :drop="drop"
                          :drop-directory="dropDirectory"
                          :add-index="addIndex"
                          :extensions="extensions"
                          :accept="accept"
                          v-model="files"
                          @input-filter="inputFilter"
                          @input-file="inputFile"
                          ref="upload"
                        >
                          <i class="fa fa-plus"></i>
                          {{i18nT(`Select`)}}
                        </file-upload>
                      </div>
                    </div>
                    <div class="text-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="!$refs.upload || !$refs.upload.active"
                        @click.prevent="$refs.upload.active = true"
                        @click="onPublish"
                      >
                        <i
                          class="fa fa-arrow-up"
                          aria-hidden="true"
                        ></i>
                        {{i18nT(`Send`)}}
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </div>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->

      </b-row>
    </validation-observer>
  </section>
</template>

<script>
// import { ref } from '@vue/composition-api'
// import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  // BButton,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BAvatar,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import ImageCompressor from '@xkeshi/image-compressor'
import FileUpload from 'vue-upload-component'
import { BASE_URL, API_KEY } from '@core/utils/constants'
import useAuth from '@/auth/useAuth'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'
import MediaItem from './MediaItem'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    quillEditor,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    FileUpload,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    MediaItem
  },
  created() {
    this.updateTask()
  },
  data() {
    return {
      userType: useAuth.getUserType(),
      task: {},
      mediaObjs: {},
      comment: '',
      snowOption: {
        theme: 'snow',
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean']
          ]
        }
      },
      files: [],
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      name: 'file',
      postAction: `${BASE_URL}media`,
      headers: {
        'Auth-Key': useAuth.getToken(),
        'Api-Key': API_KEY
      },
      // data: {
      //   '_csrf_token': 'xxxxxx',
      // },
      autoCompress: 1024 * 1024,
      uploadAuto: true,
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      },
      editFile: {
        show: false,
        name: ''
      },
      required,
      email,
      medias: [],
      completeBtnClicked: false
    }
  },
  methods: {
    updateTask() {
      this.$http
        .get(`tasks?id=${router.currentRoute.params.id}`)
        .then(({ data }) => {
          this.task = data.data
          const attachments = _.flatten(
            data.data.TaskComments.map(comment => comment.Medias)
          )

          this.medias = attachments
          for (const id in data.data.TaskDocuments) {
            this.medias.push(parseInt(id))
          }
        })
    },
    onPublish() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const mediaIds = this.$refs.upload.files
            .map(file => file.response.data.Id)
            .join(',')
          const formData = new FormData()
          formData.append('task_id', router.currentRoute.params.id)
          formData.append('comment', this.comment)
          if (mediaIds) formData.append('task_medias', mediaIds)

          this.$http.post('tasks/addComment', formData).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Added comment`,
                icon: 'InfoIcon',
                variant: 'success'
              }
            })
            this.comment = ''
            this.$refs.upload.clear()

            this.updateTask()
          })
        } else {
          document.querySelector('#quill-editor-wrapper').scrollIntoView({
            behavior: 'smooth'
          })
        }
      })
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
        if (
          newFile.file &&
          newFile.error === '' &&
          newFile.type.substr(0, 6) === 'image/' &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing'
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512
          })
          imageCompressor
            .compress(newFile.file)
            .then(file => {
              this.$refs.upload.update(newFile, {
                error: '',
                file,
                size: file.size,
                type: file.type
              })
            })
            .catch(err => {
              this.$refs.upload.update(newFile, {
                error: err.response.message || 'compress'
              })
            })
        }
      }
      if (
        newFile &&
        newFile.error === '' &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
      if (
        newFile &&
        newFile.error === '' &&
        newFile.type.substr(0, 6) === 'image/' &&
        newFile.blob &&
        (!oldFile || newFile.blob !== oldFile.blob)
      ) {
        newFile.error = 'image parsing'
        let img = new Image()
        img.onload = () => {
          this.$refs.upload.update(newFile, {
            error: '',
            height: img.height,
            width: img.width
          })
        }
        img.οnerrοr = () => {
          this.$refs.upload.update(newFile, { error: 'parsing image size' })
        }
        img.src = newFile.blob
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active) {
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: 'size' })
          }
        }
      }
      if (!newFile && oldFile) {
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    alert(message) {
      alert(message)
    },
    // add folder
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert('Your browser does not support')
        return
      }
      let input = document.createElement('input')
      input.style =
        'background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;'
      input.type = 'file'
      input.setAttribute('allowdirs', true)
      input.setAttribute('directory', true)
      input.setAttribute('webkitdirectory', true)
      input.multiple = true
      document.querySelector('body').appendChild(input)
      input.click()
      input.onchange = () => {
        this.$refs.upload.addInputFile(input).then(function() {
          document.querySelector('body').removeChild(input)
        })
      }
    },
    onAddData() {
      this.addData.show = false
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support')
        return
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type
      })
      this.$refs.upload.add(file)
    },
    formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      return size.toString() + ' B'
    },
    completeTask() {
      this.completeBtnClicked = !this.completeBtnClicked
      setTimeout(() => {
        if (!this.completeBtnClicked) return
        const formData = new FormData()
        formData.append('id', this.task.Id)
        formData.append('completed', 1 - this.task.Completed)
        this.$http
          .post('tasks/completedStatus', formData)
          .then(() => {
            this.updateTask()
            this.completeBtnClicked = false
          })
          .catch(() => {
            this.updateTask()
          })
      }, 2000)
    },
    onCopy() {
      this.$http.post(`tasks/copyTask?task_id=${this.task.Id}`).then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.successMessage(res.data),
            icon: 'InfoIcon',
            variant: 'success'
          }
        })

        const taskId = res.data.data.Id
        this.$router.push({ name: 'task-view', params: { id: taskId } })
      })
    },
    onDelete() {
      this.$swal({
        title: this.i18nT(`Are you sure?`),
        text: this.i18nT(`You won't be able to revert this!`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.i18nT(`Yes, delete it!`),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete(`tasks?id=${this.task.Id}`).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.successMessage(res.data),
                icon: 'InfoIcon',
                variant: 'success'
              }
            })

            this.$router.back()
          })
        }
      })
    }
  },
  computed:{
    employeeProfilePath(){
      if(this.userType!=='Freelancer') {
        return {
          name: 'view-employee',
              params: { id: this.task.AssignedUser.Id, software: 'hr' },

        }
      } else{
        return {
          name: 'tasks'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss">
.ql-editor {
  min-height: 200px !important;
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}
.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
  transform: unset;
}
.example-full label.dropdown-item {
  margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}
.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}
.example-full .filename {
  margin-bottom: 0.3rem;
}
.example-full .btn-is-option {
  margin-top: 0.25rem;
}
.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}
.example-full .edit-image img {
  max-width: 100%;
}
.example-full .edit-image-tool {
  margin-top: 0.6rem;
}
.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}
.example-full .footer-status {
  padding-top: 0.4rem;
}
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.sk-dropzone-boundary {
    border: 2px dashed #e9ecef;
}

.timestamp {
  color: gray;
  font-size: 12px;
}
</style>
